<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <header-egroweed-nft
        v-show="!isDark"
        v-if="!isZero"
        class="w-100"
      />
      <logo-bancannabis-black
        v-show="isDark"
        v-if="!isZero"
        class="w-100"
      />
      <logo-zero-full
        v-if="isZero"
        class="w-100"
      />

      <!-- Login v1 -->
      <b-card class="mb-0">
        <dark-Toggler
          class="d-lg-block"
          :style="{'float':'right', 'color':isZero?'#75c469':''}"
        />

        <b-card-title class="mb-1">
          Welcome to
          {{ isZero ? "ZeroCarniZero" : "the decentralized cannabis community! 🌿" }}
        </b-card-title>

        <b-card-text
          v-show="showBancannabisAuth==false&&doMetamaskRequest==false"
          class="mb-2"
        >
          Join us for the best path to
          {{ isZero ? "participe in the vegan" : "democratization and participation in the cannabis" }}
          industry!
        </b-card-text>

        <b-form
          class="auth-login-form"
          @submit.prevent
        >
          <!-- Network -->
          <!-- <b-form-group
            label-for="blockchain"
            label="Blockchain Network"
          >
            <v-select
              v-model="blockchainId"
              label="title"
              :options="blockchains"
              @input="addNetwork()"
            >
              <template #option="{ title, icon }">
                <feather-icon
                  :icon="icon"
                  size="16"
                  class="align-middle mr-50"
                />
                <span> {{ title }}</span>
              </template>
            </v-select>
          </b-form-group> -->
        </b-form>

        <!-- <b-card-text
          v-show="showBancannabisAuth==false&&doMetamaskRequest==false"
          class="mb-2"
        >
          Log in or register
        </b-card-text> -->
        <b-button
          v-if="showBancannabisAuth==false"
          v-ripple.400="'rgba(114, 47, 10, 0)'"
          variant="outline-success"
          :style="'background-color:white'"
          block
          @click.prevent="showBancannabisAuth=true"
        >
          {{ isZero ? "ZeroCarniZero " : " " }}
          Sign In &nbsp;&nbsp;&nbsp;
          <!-- <bancannabis-logo v-if="!isZero" /> -->
          <zerocarnizero-logo v-if="isZero" />
        </b-button>
        <b-link
          v-if="showBancannabisAuth==false"
          :to="{name:'auth-register', params: { iz: isZero }}"
          :style="{ 'text-align': 'center', 'display':'block', 'color':isZero?'#75c469':''}"
        ><br>
          <span>Join the community and get started!</span>
        </b-link>

        <!-- <hr class="divider-text m-2"> -->
        <b-card-text
          v-show="showBancannabisAuth"
          class="mb-2"
        >
          Please sign-in to your
          {{ isZero ? "ZeroCarniZero " : "Bancannabis " }}
          account
        </b-card-text>

        <!-- form -->
        <validation-observer
          v-show="showBancannabisAuth"
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              label="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="john@example.com"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <!-- <b-link :to="{name:'auth-forgot-password-v1'}">
                  <small>Forgot Password?</small>
                </b-link> -->
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <!-- <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                Remember Me
              </b-form-checkbox>
              <br>
              ⚠️ Not your computer? Use a Private Browsing window to sign in.
            </b-form-group> -->

            <!-- submit button -->
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              type="submit"
              block
              :disabled="invalid"
              :style="{'background-color':isZero?'#75c469 !important':'', 'color': isZero?'white !important':'', 'border-color': isZero?'white !important':''}"
              @click.prevent="validationForm"
            >
              Sign in
              <b-spinner
                v-show="doLoadingRequest"
                small
              />
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-secundary"
              type="submit"
              block
              @click.prevent="showBancannabisAuth=false"
            >
              Cancel
            </b-button>
          </b-form>
        </validation-observer>
        <!-- <hr class="divider-text m-2"> -->
        <!-- submit button -->
        <!-- <b-button
          v-if="showMetamaskButton&&showBancannabisAuth==false"
          variant="primary"
          block
          disabled
          @click.prevent="metamaskLoginTap"
        >
          Metamask &nbsp;&nbsp;&nbsp;
          <metamask-logo /> &nbsp;&nbsp;&nbsp;
          <b-spinner
            v-show="doMetamaskRequest"
            small
          />
        </b-button> -->
        <!-- <vue-metamask
          v-if="doMetamaskRequest"
          @onComplete="onCompleteMetamask"
        /> -->
        <!-- <b-card-text class="text-center mt-2">
          <b-card-title class="mb-1">
            New on our platform?
          </b-card-title>
          <span>Create an account of Bancannabis.org <b>Now!</b>.</span>
          <b-link :to="{name:'auth-register'}"><br>
            <span>Create a new Account</span>
          </b-link>
        </b-card-text> -->
        <!-- <b-card-text
          v-show="!showBancannabisAuth"
          class="text-center mt-2"
        >
          <span>Add BSC Network to Metamask</span>
          <b-link @click="addBscToMetamask"><br>
            <span>Clic here!</span>
          </b-link>
        </b-card-text> -->
      </b-card>
      <!-- /Login v1 -->
    </div>

    <!-- how add bsc to metamask -->
    <b-modal
      id="modal-bsc-to-metamask"
      title="Add to Metamask the Binance Smart Chain (Tutorial)"
      ok-only
      ok-title="Ok"
    >
      <b-card-text>
        <div
          class="vx-col w-full"
          style="border: 1px solid forestgreen; margin:1px; text-align:justify;height: 400px;overflow-y: scroll;"
        >
          <div style="width:100%; text-align:center">
            <b-img
              :src="require(`@/assets/images/pages/metamaskicon.png`)"
              class="product-img"
              fluid
            />
            <logo-bnb style="width:50px" />&nbsp;
          </div>
          <p>&nbsp;</p>
          <p class="text-center">
            <strong>Add Binance Network (BSC)</strong>
          </p>
          <p>
            <span>
              <b-img
                :src="require(`@/assets/images/pages/login/binance-metamask-begin.png`)"
                class="product-img w-80"
                fluid
              />
            </span>
          </p>
          <p>&nbsp;</p>
          <p class="text-center">
            <strong>
              now you can access with the Metamask option.
              <br> Let's Plant!
            </strong>
          </p>
        </div>
      </b-card-text>
    </b-modal>

    <!-- how add bsc to metamask -->
    <b-modal
      id="modal-metamask-email"
      title="Metamask SignIn"
      ok-only
      ok-title="Aceptar"
      @hidden="onHideMetamaskEmailRegisterHandle"
    >
      <b-card-text>
        <div
          class="vx-col w-full"
          style="border: 1px solid forestgreen; margin:1px;"
        >
          <div style="width:100%; text-align:center">
            <b-img
              :src="require(`@/assets/images/pages/metamaskicon.png`)"
              class="product-img"
              fluid
            />
            <br>
            To Sigin in E-Groweed with Metamask you need provide an valid email address.
          </div>
          <p class="text-center">
            <b-form
              class="auth-login-form mt-1"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label-for="email"
                label="Email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="metamaskEmail"
                    name="login-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="john@example.com"
                    autofocus
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-form>
          </p>
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BImg, BSpinner, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import HeaderEgroweedNft from '@core/layouts/components/HeaderLoginNft.vue'
import LogoBancannabisBlack from '@core/layouts/components/LogoBancannabisBlack.vue'
// import MetamaskLogo from '@core/layouts/components/LogoMetamask.vue'
// import BancannabisLogo from '@core/layouts/components/LogoBancannabis.vue'
import ZerocarnizeroLogo from '@core/layouts/components/LogoZeroCarniZero.vue'
import LogoZeroFull from '@core/layouts/components/LogoZeroFull.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import logoBnb from '@core/layouts/components/logoBnb.vue'
import { computed } from '@vue/composition-api'

export default {
  components: {
    // BSV
    BButton,
    BSpinner,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    // VuexyLogo,
    // MetamaskLogo,
    // BancannabisLogo,
    ZerocarnizeroLogo,
    LogoZeroFull,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    // BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    DarkToggler,
    HeaderEgroweedNft,
    LogoBancannabisBlack,
    BImg,
    logoBnb,
    // vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      isZero: false, // show zerocarnizero login
      userEmail: '',
      password: '',
      status: '',
      doLoadingRequest: false,
      // validation rules
      required,
      email,
      invData: {},
      invitationCode: this.$route.query.code, // ingresar con codigo de invitacion
      responseAuth: null,
      reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      // mostrar boton metamask (Solo navegadores NO MOBILES)
      showMetamaskButton: true,
      // saber si se esta solicitando loguear con metamask
      doMetamaskRequest: false,
      metamaskData: null,
      metamaskSignature: null,
      metamaskUserNonce: 0,

      // si se da click en metamask entonces ocultara el formulario de logueo
      showBancannabisAuth: false, // Toggle entre bancannabis auth o metamask
      metamaskEmail: '',
      blockchainId: {
        title: 'Binance (BSC) Mainnet',
        icon: 'BoxIcon',
        id: 0x38,
        name: 'Binance Coin',
        provider: 'https://bsc-dataseed.binance.org/',
        scanner: 'https://bscscan.com',
        symbol: 'BNB',
      },
      // {
      //   title: 'GANACHE Mainnet',
      //   icon: 'BoxIcon',
      //   id: 5777,
      //   name: 'GANACHE',
      //   provider: 'HTTP://127.0.0.1:7545',
      //   scanner: '',
      //   symbol: 'BNB',
      // },
      blockchains: [
        {
          title: 'Binance (BSC) Mainnet',
          icon: 'BoxIcon',
          id: 0x38,
          name: 'Binance Coin',
          provider: 'https://bsc-dataseed.binance.org/',
          scanner: 'https://bscscan.com',
          symbol: 'BNB',
        },
        // {
        //   title: 'Ethereum Mainnet',
        //   icon: 'BoxIcon',
        //   id: 0x1,
        //   name: 'Ether',
        //   provider: 'https://mainnet.infura.io/v3/',
        //   scanner: 'https://etherscan.io',
        //   symbol: 'ETH',
        // },
        // {
        //   title: 'Matic Mainnet',
        //   icon: 'BoxIcon',
        //   id: 0x89,
        //   name: 'Polygon Coin',
        //   provider: 'https://rpc-mainnet.maticvigil.com/',
        //   scanner: 'https://bscscan.com',
        //   symbol: 'MATIC',
        // },
        // {
        //   title: 'Binance Smart Chain (TESTNET)',
        //   icon: 'BoxIcon',
        //   id: 0x61,
        //   name: 'Binance Coin',
        //   provider: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
        //   scanner: 'https://bscscan.com',
        //   symbol: 'BNB',
        // },
        // {
        //   title: 'CELO Mainnet',
        //   icon: 'BoxIcon',
        //   id: 0xA4EC,
        //   name: 'CELO',
        //   provider: 'https://celo-mainnet.infura.io',
        //   scanner: 'https://celoscan.io',
        //   symbol: 'CELO',
        // },
      ],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {
    // Borrar las sessiones abiertas
    this.$sessionData.removeAll()
    // validar si es un celular y ocultar el boton Metamask
    if (this.isMobile()) {
      this.showMetamaskButton = false
      this.showBancannabisAuth = false
    }
    this.isZero = this.$route.query.iz === 'active' || this.$route.params.iz
    if (this.isZero) {
      this.blockchainId = {
        title: 'CELO Mainnet',
        icon: 'BoxIcon',
        id: 0xA4EC,
        name: 'CELO',
        provider: 'https://celo-mainnet.infura.io',
        scanner: 'https://celoscan.io',
        symbol: 'CELO',
      }
      this.$sessionData.setNetwork(this.blockchainId.id)
    }
  },
  methods: {
    // Si es un nuevo usuario, verificamos que ingrese un email para su metamask
    async onHideMetamaskEmailRegisterHandle() {
      if (this.metamaskEmail === '' || !this.metamaskEmail) {
        this.doMetamaskRequest = false
        return
      }
      const userDetails = {
        wallet: this.metamaskData.metaMaskAddress,
        nonce: 0,
        email: this.metamaskEmail,
        metamask: true,
        referral: '',
      }
      // registrar usuario
      await this.$http.post(`/user?nid=${this.blockchainId.id}`, userDetails)
        .then(usrCreated => {
          const first = 0
          this.metamaskUserNonce = usrCreated.data[first].nonce
          return this.metamaskUserNonce
          /*
            this.$httpBca.post('/auth/local/register', {
              provider: 'Metamask',
              wallet: userDetails.wallet,
            })
          */
        })
        .then(async nonce => {
          if (nonce === 0) return nonce
          // autenticar el usuario una vez firmada la signature
          await this.handleSignMsg()
          return nonce
        })
        .then(nonce => {
          if (nonce === 0) return nonce
          this.handleAutenticateMsg()
            .then(async user => {
              this.userEmail = user.email
              const userData = {
                data: [
                  {
                    token: user.token,
                    wallet: user.wallet,
                    isGm: user.isGm,
                  },
                ],
              }
              await this.getKycDataSesion(userData)
              this.successLogin()
              this.doMetamaskRequest = false
              return true
            })
            .catch(() => {
              this.doMetamaskRequest = false
              return false
            })
          return nonce
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Alerta!',
              icon: 'CloseIcon',
              variant: 'warning',
              text: 'We sorry, cant create the user at this moment, please try again.',
            },
          })
          this.doMetamaskRequest = false
          return false
        })
    },
    addNetwork() {
      this.addNetworkToMetamask()
    },
    addNetworkToMetamask() {
      // nuevo ID de la cadena
      const newChainId = this.blockchainId.id
      window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
          chainId: newChainId,
          chainName: this.blockchainId.title,
          nativeCurrency: {
            name: this.blockchainId.name,
            symbol: this.blockchainId.symbol,
            decimals: 18,
          },
          rpcUrls: [this.blockchainId.provider],
          blockExplorerUrls: [this.blockchainId.scanner],
        }],
      })
    },
    addBscToMetamask() {
      window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
          chainId: 0x38,
          chainName: 'Binance Smart Chain',
          nativeCurrency: {
            name: 'Binance Coin',
            symbol: 'BNB',
            decimals: 18,
          },
          rpcUrls: ['https://bsc-dataseed.binance.org/'],
          blockExplorerUrls: ['https://bscscan.com'],
        }],
      })
    },
    openBscInfo() {
      this.$bvModal.show('modal-bsc-to-metamask')
    },
    async onCompleteMetamask(data) {
      if (this.blockchainId.id !== data.netID) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text: `Auto-select network failed, please select the ${this.blockchainId.title} network in your Metamask...`,
            variant: 'danger',
          },
        },
        {
          position: 'bottom-center',
          timeout: 5000,
        })
        this.doMetamaskRequest = false
        return
      }
      if (data.type === 'NO_INSTALL_METAMASK') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text: 'Metamask not detected, please verify...',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-center',
          timeout: 5000,
        })
        this.doMetamaskRequest = false
        return
      }
      this.metamaskData = data
      if (data.metaMaskAddress) {
        // Validar informacion del usuario segun la informacion de metamask
        this.existsWallet(data.metaMaskAddress)
          .then(async nonce => {
            let usrNonce = nonce
            if (!nonce) usrNonce = 0
            // si la wallet existe
            // realizar signature para validar usuario
            if (usrNonce !== 0) {
              this.metamaskUserNonce = usrNonce
            }
            // si existe devolver el nonce y si no, registrar el usuario
            return usrNonce !== 0 ? usrNonce : this.handleSignIn()
          })
          .then(async nonce => {
            if (nonce === 0 || !nonce) return nonce
            // autenticar el usuario una vez firmada la signature
            await this.handleSignMsg()
            return nonce
          })
          .then(nonce => {
            if (nonce === 0 || !nonce) return nonce
            this.handleAutenticateMsg()
              .then(async user => {
                this.userEmail = user.email
                const userData = {
                  data: [
                    {
                      token: user.token,
                      wallet: user.wallet,
                    },
                  ],
                }
                await this.getKycDataSesion(userData)
                this.successLogin()
                this.doMetamaskRequest = false
                return true
              })
              .catch(() => {
                this.doMetamaskRequest = false
                return false
              })
            return nonce
          })
          .catch(() => {
            // si no existe
            /*
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'InfoIcon',
                  text: 'We cant find your wallet, please verify...',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-center',
                timeout: 5000,
              })
            */
            this.doMetamaskRequest = false
          })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Alerta!',
            icon: 'CloseIcon',
            variant: 'warning',
            text: 'We sorry, cant get metamask data, please try again.',
          },
        })
        this.doMetamaskRequest = false
      }
    },
    // Registro de usuario mediante su clave publica
    async handleSignIn() {
      this.$bvModal.show('modal-metamask-email')
    },
    // firmar un mensaje para validar el usuario
    handleSignMsg() {
      return new Promise((resolve, reject) => {
        const nonce = this.metamaskUserNonce
        if (nonce === 0) reject(new Error('Cant sign now'))
        try {
          this.metamaskData.web3.eth.personal.sign(
            this.metamaskData.web3.utils.fromUtf8(`I am signing my e-groweed.com account: ${nonce}`),
            this.metamaskData.metaMaskAddress,
          )
            .then(signature => {
              this.metamaskSignature = signature
              resolve()
            })
            .catch(err => {
              if (err) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Notification',
                    icon: 'InfoIcon',
                    text: 'Cant verify your signature, please try again',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-center',
                  timeout: 5000,
                })
                reject()
              }
            })
        } catch (error) {
          reject(new Error(error))
        }
      })
    },
    handleAutenticateMsg() {
      return new Promise((resolve, reject) => {
        this.$http.post(`/auth/signature?nid=${this.blockchainId.id}`, {
          wallet: this.metamaskData.metaMaskAddress,
          signature: this.metamaskSignature,
        })
          .then(response => {
            if (response.data.valid) {
              resolve(response.data.user)
            }
          })
          .catch(err => {
            reject(new Error(err))
          })
      })
    },
    isMobile() {
      return (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent))
    },
    /**
     * Funcion encargada de validar login con Metamask
     */
    metamaskLoginTap() {
      if (this.doMetamaskRequest) return
      // ocultar login Bancannabis
      this.showBancannabisAuth = false
      // cambiar estado y activar spinner, ademas abre autenticacion de metamask
      this.doMetamaskRequest = true
    },
    existsWallet(wallet) {
      return new Promise(resolve => {
        this.$http.get(`/auth/${wallet}?nid=${this.blockchainId.id}`)
          .then(response => {
            if (response.data.valid) {
              resolve(response.data.nonce)
            }
          })
          .catch(() => {
            resolve(0)
          })
      })
    },
    validationForm() {
      this.$refs.loginForm.validate().then(async success => {
        if (success) {
          if (await this.login()) {
            this.successLogin()
          }
        }
      })
    },
    successLogin() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notification',
          icon: 'InfoIcon',
          text: 'The data has been verified successfull... redirecting.',
          variant: 'success',
        },
      },
      {
        position: 'bottom-center',
        timeout: 8000,
      })
      // Guardar de forma segura la informacion del usuario en el navegador
      this.$sessionData.set(this.responseAuth)
      this.$sessionData.setNetwork(this.blockchainId.id)
      setTimeout(() => {
        this.$router.push({ name: (this.isZero ? '/' : '/'), params: { iz: this.isZero } })
      }, 500)
    },
    /**
     * Obtiene los datos de un codigo de invitacion
     */
    async getInvitacionData() {
      if (this.invitationCode !== '' && this.invitationCode !== undefined) {
        return this.$http.get(`/invitation/${this.invitationCode}?nid=${this.blockchainId.id}`).catch(() => false)
      }
      return null
    },
    /**
     * Obtener los datos basicos de un Kyc para obtener los datos de la sesion
     */
    async getKycDataSesion(_emailValid, byEmail = true) {
      if (!this.userEmail) return false
      const strUrl = `/kyc/${(byEmail) ? this.userEmail : this.metamaskData.metaMaskAddress}/${(byEmail) ? 'email' : 'wallet'}?nid=${this.blockchainId.id}`
      this.sessionToken = _emailValid.data[0].token
      // get data
      this.$http.defaults.headers['hey!'] = 'jg3*'
      this.$http.defaults.headers['x-access-token'] = this.sessionToken
      let responseKyc = await this.$http.get(strUrl).catch(() => {
        try {
          // Si no se ha llenado el KYC
          // default
          /* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
          this.responseAuth = {
            metamaskSigin: this.doMetamaskRequest,
            isZero: this.isZero,
            token: this.sessionToken,
            kyc: '',
            id: _emailValid.data[0]._id,
            isGm: _emailValid.data[0].isGm,
            invitation: (this.invitationCode) ? this.invitationCode : '',
            data: {
              kyc: {
                name: 'New',
                lastName: 'User',
              },
              invitation: {
                wallet: _emailValid.data[0].wallet ? _emailValid.data[0].wallet : this.metamaskData.metaMaskAddress,
                email: this.userEmail,
              },
            },
          }
          return false
        } catch (er) {
          return false
        }
      })
      // Si existe algun kyc
      if (responseKyc) {
        responseKyc = responseKyc.data
        this.responseAuth = {
          isZero: this.isZero,
          metamaskSigin: this.doMetamaskRequest,
          token: this.sessionToken,
          id: _emailValid.data[0]._id,
          isGm: _emailValid.data[0].isGm,
          kyc: responseKyc.kyc.id,
          invitation: (this.invitationCode) ? this.invitationCode : '',
          data: {
            kyc: responseKyc.kyc,
            invitation: {
              wallet: responseKyc.kyc.wallet ? responseKyc.kyc.wallet : this.metamaskData.metaMaskAddress,
              email: this.userEmail,
            },
          },
        }
      }
      return true
    },
    /**
     * Asignar datos de usuario
     * @return true si existe respuesta de la autenticacion
     * @return false si las credenciales o codigo de invitacion son erroroneas
     */
    setInvitationData() {
      // si obtenemos algun dato
      if (this.responseAuth) {
        this.responseAuth.invitation = this.invitationCode
        return true
      }
      return false
    },
    /**
     * Validar un usuario y una contraseña
     */
    async validateUserAndPassword() {
      return new Promise(resolve => {
        if (this.userEmail !== '' && this.password !== '') {
          if (this.reg.test(this.userEmail)) {
            this.$http.post(`/auth?nid=${this.blockchainId.id}`, {
              pass: this.password,
              login: this.userEmail,
            })
              .then(async emailValid => {
                // Validar error de login
                if (!emailValid.error) {
                  await this.getKycDataSesion(emailValid)
                  resolve()
                }
              })
              .catch(e => {
                this.responseAuth = null
                this.doLoadingRequest = false
                // alerta
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Notification',
                    icon: 'InfoIcon',
                    text: (e.data) ? e.data.message : 'Authentication failed, please try again.',
                    variant: 'warning',
                  },
                },
                {
                  position: 'bottom-center',
                  timeout: 5000,
                })
              })
          }
        } else resolve()
      })
    },
    /**
     * Ejecuta la peticion al servidor para validar los datos del usuario
     * Ya sea por código de verificacion o usuario y contraseña
     */
    async login() {
      // salir SOLO si no se han ingresado almenos un codigo de invitacion o un email o si se esta realizando el login
      if ((this.invitationCode === '' && this.userEmail === '') || this.doLoadingRequest) return false
      this.doLoadingRequest = true
      // verificar por codigo
      await this.getInvitacionData()
      // si no es por codigo de invitacion y se desea validar un usuario
      if (!this.responseAuth) await this.validateUserAndPassword()
      // Asignar datos de envio
      if (this.setInvitationData()) {
        this.doLoadingRequest = false
        return true
      }
      this.doLoadingRequest = false
      return false
    },
  },
  setup() {
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
